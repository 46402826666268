<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title
          class="py-0 pt-5"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img
              src="@/assets/basketLogoDialogDistrictManagement.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-1 dialogTitle-text">Game Info</div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="27"
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="toggleListGameInfoModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <div class="text-center" s style="cursor: pointer">
            <hr
              style="
                height: 2px;
                border-width: 0;
                color: gray;
                background-color: gray;
              "
            />
            <div
              style="
                text-align: justify;
                font-size: 16px;
                text-decoration: underline;
              "
            >
              <strong v-if="this.adminAccess == 'Player'"
                >{{ this.gameData.game_date_str }},
                {{ this.gameData.game_time_str }}-{{
                  converTime(this.gameData.end_time)
                }}
                | {{ this.gameData.game_timezone }}</strong
              >
              <strong v-else @click="visitGamePanelHandler()"
                >{{ this.gameData.game_date_str }},
                {{ this.gameData.game_time_str }}-{{
                  converTime(this.gameData.end_time)
                }}
                | {{ this.gameData.game_timezone }}</strong
              >
              &nbsp;&nbsp;&nbsp;
              <v-icon
                dark
                class="icons"
                color="#6B6B6B"
                size="22"
                @click="
                  toggleAddEditViewGameModal({
                    show: true,
                    type: 'edit',
                    dashboard: true,
                    id: gameData.id,
                  })
                "
                v-if="this.adminAccess != 'Player'"
              >
                mdi-pencil
              </v-icon>
              <!--   <v-icon @click="closeEventPopup()">mdi-close</v-icon>-->
              <hr
                style="
                  height: 2px;
                  border-width: 0;
                  color: gray;
                  background-color: gray;
                "
              />
            </div>
            <br />
            <div v-if="this.gameData.player_confirmations.length != 0">
              <strong style="margin-right: 357px; font-size: 17px"
                >Players:
              </strong>
              <div v-for="(monthlySlot, index) in playerGameItems" :key="index">
                <tr class="students">
                  <td>{{ monthlySlot.player_name }}</td>
                  &nbsp;&nbsp;
                  <td
                    style="color: green; font-weight: 600"
                    v-if="monthlySlot.confirmation_status == 'confirmed'"
                  >
                    Confirmed
                  </td>
                  <td style="color: red; font-weight: 600" v-else>
                    Not Confirmed
                  </td>
                </tr>
              </div>
            </div>

            <br />
            <div style="text-align: justify; font-size: 16px">
              <strong>Game City and State: </strong>{{ this.gameData.city }},{{
                this.gameData.state_name
              }}
              <br />
              <br />
              <strong style="color: red">Ticket Sold Info: </strong
              >{{ this.gameData.total_online_ticket_sold }} /
              {{ this.gameData.total_seat }}
              <br />
              <br />
              <!-- <strong style="color: red">Presale Ticket: </strong
                >{{ this.gameData.total_ticket_sold }} as of
                {{ this.gameData.presale_date_str }}
                <br />
                <br />-->
              <strong style="font-size: 20px; text-decoration: underline"
                >Previous Event Info:</strong
              >
              <br />
              <strong>Year: </strong>2023
              <br />
              <strong>Ticket Sale: </strong
              >{{ this.gameData.total_ticket_sold }}
              <br />
              <strong>Team: </strong>{{ this.gameData.team_name }}
              <br />
              <br />
              <br />
              <strong style="font-size: 20px; text-decoration: underline"
                >Contact Info:
              </strong>
              <br />
              <strong>Host Name: </strong
              >{{ this.gameData.host_name }} &nbsp;&nbsp;&nbsp;
              <br />
              <strong>Cell Phone: </strong>{{ this.gameData.host_phone }}
              <br />
              <strong>Email: </strong>{{ this.gameData.host_email }}
              <br />
              <strong>Sales Rep: </strong>{{ this.gameData.sales_rep_name }}
              <br />
              <strong>Event Support Wizard: </strong
              >{{ this.gameData.event_support_staff }}
              <br />
              <br />
              <br />
              <div v-if="this.gameData.assemble_data_sameday != null">
                <strong style="font-size: 16px; text-decoration: underline">
                  FREE ASSEMBLY SCHEDULE:
                </strong>
                <br />

                <strong>Date: </strong
                >{{ this.gameData.assemble_data_sameday[0].assemble_date_2 }}
                <br />
                <strong>Time: </strong
                >{{ this.gameData.assemble_data_sameday[0].start_time_str }}-{{
                  this.gameData.assemble_data_sameday[0].end_time_str
                }}
                <br />
              </div>
              <br />
              <br />
              <br />

              <strong style="font-size: 20px; text-decoration: underline"
                >General Details:
              </strong>
              <br />
              <strong>Host Group Name: </strong>Host Group Name
              <br />
              <strong>Game Date & Time: </strong
              >{{ this.gameData.game_date_str }}
              {{ converTime(this.gameData.start_time) }}-{{
                converTime(this.gameData.end_time)
              }}
              <br />
              <strong>Arrival Time: </strong
              >{{ this.gameData.arrival_time_str }}
              <br />
              <strong>Site: </strong>{{ this.gameData.event_location_name }}
              <br />
              <strong> Event Address: </strong
              >{{ this.gameData.event_location }}, {{ this.gameData.city }},
              {{ this.gameData.state_name }}
              <br />
              <strong>Seating Capacity: </strong>-
              <br />
              <strong>Contract Type: </strong>{{ this.gameData.contract_type }}

              <br />
              <strong>Note: </strong>
              <div v-html="this.gameData.notes"></div>
              <!--  <ul>
                  <li v-for="(note, index) in gameNotes" :key="index">
                    <div v-html="note"> </div>
                  </li>
                </ul>-->
              <br />
              <br />
              <br />
              <div v-if="this.gameData.ticket_data != null">
                <strong style="font-size: 20px; text-decoration: underline"
                  >Ticket Prices:
                </strong>
                <v-col cols="12">
                  <div class="td-container">
                    <td>
                      <label>Name</label>
                      <tr
                        v-for="(items, i) in tickettypeitems"
                        :key="i"
                        v-text="items.item_name"
                      ></tr>
                    </td>

                    <td>
                      <label>ADV</label>
                      <tr
                        v-for="(items, p) in tickettypeitems"
                        :key="p"
                        v-text="items.default_price"
                      ></tr>
                    </td>

                    <td>
                      <label>Door</label>
                      <tr
                        v-for="(items, p) in tickettypeitems"
                        :key="p"
                        v-text="items.door_price"
                      ></tr>
                    </td>
                  </div>
                </v-col>
              </div>
              <br />
              <br />
              <br />
              <div v-if="this.gameData.hotel_data != null">
                <strong style="font-size: 20px; text-decoration: underline"
                  >Hotel-
                </strong>
                <br />
                <strong>Name: </strong>{{ this.gamehotalData.name }}
                <br />
                <strong>Address: </strong>{{ this.gamehotalData.hotel_address }}
                <br />
                <strong>Email: </strong>{{ this.gamehotalData.contact_email }}
                <br />
                <strong>Confirmation: </strong
                >{{ this.gamehotalData.confirmation_number }}
                <br />
                <strong>Reservation Under: </strong
                >{{ this.gamehotalData.contact_person_name }}
              </div>

              <br />
              <br />
            </div>
            <hr
              style="
                height: 2px;
                border-width: 0;
                color: gray;
                background-color: gray;
              "
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <game-modal></game-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { ADMIN_GAMES_GET_DETAIL } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { ROUTER_URL } from "../../../constants/urls";
export default {
  name: "ListCalendarManagement",
  components: {
    GameModal: () =>
      import("../../GameManagement/AddEditGameModal/AddEditGameModal"),
  },
  data() {
    return {
      adminAccess: localStorage.getItem("adminAccess"),
      loading: false,
      formLoading: false,
      gameData: {},
      playerGameItems: [],
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "calendarManagement/getShowListGameInfo",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    layout() {
      return this.$route.meta.layout;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleListGameInfoModal({ show: value });
      },
    },
    type() {
      return this.$store.state.calendarManagement.listGameInfoModal.type;
    },
    toastMessage() {
      if (this.type === "add") {
        return "Task Added";
      } else {
        return "Task Updated";
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add New Task";
        case "edit":
          return "Edit Event";
        default:
          return "";
      }
    },
  },
  watch: {
    show(value) {
      if (value) {
        // Modal open callback
        this.openCallBackModal();
      } else {
        // Modal close callback
        this.closeCallBackModal();
      }
    },
  },
  methods: {
    ...mapActions({
      // sponsor Modal
      toggleListGameInfoModal: "calendarManagement/toggleListGameInfoModal",
      toggleAddEditViewGameModal: "gameManagement/toggleModal",
      showToast: "snackBar/showToast",
    }),
    openCallBackModal() {
      console.log("open modal");
      this.getGameDetail();
    },
    closeCallBackModal() {},

    getGameDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.gameData = res.data.game_detail;
        if (this.gameData.ticket_data != null) {
          this.tickettypeitems = this.gameData.ticket_data;
        }
        if (this.gameData.hotel_data != null) {
          this.gamehotalData = this.gameData.hotel_data[0];
        }
        this.playerGameItems = this.gameData.player_confirmations;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] =
        this.$store.state.calendarManagement.listGameInfoModal.gameID;
      Axios.request_GET(
        ADMIN_GAMES_GET_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    converTime(value) {
      if (value) {
        var temp = value.split(":");
        if (parseInt(temp[0]) > 12) {
          value = parseInt(temp[0]) - 12 + ":" + temp[1] + " PM";
        } else {
          value = temp[0] + ":" + temp[1] + " AM";
        }
      }

      return value;
    },
    visitGamePanelHandler() {
      let routerData = this.$router.resolve({
        name: ROUTER_URL.gamePanel.children.dashboard.name,
        query: {
          game_id:
            this.$store.state.calendarManagement.listGameInfoModal.gameID,
        },
      });
      window.open(routerData.href, "_blank");
    },
  },
};
</script>
<style scoped>
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
</style>
